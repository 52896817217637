<template>
    <div>
      <Divider dashed ><span class="divider-text">流量覆盖统计</span></Divider>

      <p>上月地铁覆盖流量总计： <strong class="text-16 m-l-20">{{prevFlowData}}</strong> 人</p>
      <p>检索时间段内预估覆盖流量：<strong class="text-16 text-orange">{{scheduleFlowData}}</strong> 人</p>
    </div>
</template>

<script>
import { getmonthdata, getForecastFlowNumber } from '@/api/dw/passengerflow'
import { toNumber } from '@/utils/wnumb_own'
import { PrefixInteger } from '@/utils/index'

export default {
  props: {
    startDate: null,
    endDate: null,
    resultStations: []
  },
  data () {
    return {
      flowData: [],
      allowStations: [],
      prevFlowData: 0,
      scheduleFlowData: 0
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const currentDate = new Date()
      let year = 0
      let month = 0

      if (currentDate.getMonth() === 0) {
        month = 12
        year = currentDate.getFullYear() - 1
      } else {
        month = currentDate.getMonth()
        year = currentDate.getFullYear()
      }

      // 获取全部线路的人流量
      this.$store.dispatch('getAllowAssetList', { publisherId: this.$store.getters.token.userInfo.publisherId }).then(assets => {
        const query = {
          startDate: year + '-' + PrefixInteger(month, 2) + '-01',
          endDate: year + '-' + PrefixInteger(month, 2) + '-01',
          assetIdStr: JSON.stringify(assets.map(x => x.id))
        }

        getmonthdata(query).then(res => {
          this.flowData = res
        })
      })
    },
    loadData (startDate, endDate, stations, assetIds) {
      this.allowStations = stations

      this.prevFlowData = toNumber(this.prevMonthData)
      // 获取时间段内的预估客流量
      this.loadForecastFlow(startDate, endDate, assetIds)
    },
    formatScheduleData (startDate, endDate) {
      this.scheduleFlowData = 0

      if (startDate !== '' && endDate !== '') {
        const start = (new Date(startDate)).getTime() // 传过来的开始时间转换为毫秒
        const end = (new Date(endDate)).getTime()
        const days = parseInt(Math.abs(start - end) / 1000 / 60 / 60 / 24)

        this.scheduleFlowData = toNumber(this.dayFlow * days)
      }
    },
    loadForecastFlow (startDate, endDate, assetIds) {
      const queryModel = {
        assetIds: assetIds.join(','),
        endDate: endDate,
        startDate: startDate
      }

      getForecastFlowNumber(queryModel).then(res => {
        this.scheduleFlowData = toNumber(res)
      })
    }
  },
  computed: {
    prevMonthData () {
      let amount = 0
      this.flowData.forEach(element => {
        if (this.allowStations.find(x => x.stationId === element.stationId)) {
          amount += (parseInt(element.flowIn) + parseInt(element.flowOut))
        }
      })
      return amount
    },
    dayFlow () {
      return parseInt(this.prevMonthData / 30)
    }
  }
}
</script>
