import request from '@/utils/requestV2'
const qs = require('qs')

// 获取施工缓冲期规则
export function getProductBufferDay (data) {
  return request({
    url: '/ooh-product/v1/rule/getproductbufferday',
    method: 'POST',
    data: qs.stringify(data)
  })
}
